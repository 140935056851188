/* eslint-disable react/prop-types */
import React, { useState } from "react";
import MyTab from "../../Components/Tabs/MyTab";

function Myheader(props) {
  const optionsHeader = ["Todos", "Pendientes", "Rechazadas", "Aceptadas"];
  const [isPressed, setIsPressed] = useState(0);
  const handleTabChange = (idxOfCurrentTab) => {
    props.selectedTab(idxOfCurrentTab);
    setIsPressed(idxOfCurrentTab);
  };
  const widthTab = `${(1 / optionsHeader.length) * 100}%`;

  return (
    <header>
      <div className="tab-container">
        {optionsHeader.map((each, idx) => (
          <MyTab
            key={idx}
            btnText={each}
            tabBtnPressed={(idxOfPressed) => handleTabChange(idxOfPressed)}
            pressed={idx === isPressed}
            btnIndex={idx}
            widthTab={widthTab}
          />
        ))}
      </div>
    </header>
  );
}

export default Myheader;
