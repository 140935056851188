import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./style.css";

export default function SideNavItem({ name, to, permissionRequired }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAbleToNavigate, setIsAbleToNavigate] = useState(false);
  const handleClick = () => {
    navigate(to);
  };

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if(permissionRequired){
      if(isAdmin === "true"){
        setIsAbleToNavigate(true);
      }
    }else{
      setIsAbleToNavigate(true)
    }
  }, [isAbleToNavigate]);

    if (isAbleToNavigate){
      return(<li className="Item-List">
        <Link
          to={to}
          className={
            window.location.pathname.includes(to) ? "Nav-Item active" : "Nav-Item"
          }
          onClick={handleClick}
        >
          <span
            className={
              window.location.pathname.includes(to)
                ? "Nav-Item-Name active"
                : "Nav-Item-Name"
            }
          >
            {name}
          </span>
        </Link>
      </li>)
    }else{
      return(<div></div>)
    }
}

SideNavItem.propTypes = {
  name: PropTypes.string,
  to: PropTypes.string,
  permissionRequired: PropTypes.bool
};
