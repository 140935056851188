import React, { useEffect, useState, useRef, useContext } from "react";
import { Spinner } from "react-bootstrap";
import "./RecipeAdministration.css";
import RecipeInformationPage from "./RecipeInformationPage";
import RecipeResolution from "./RecipeResolution";
import MyTab from "../../Components/Tabs/MyTab";
import { useParams, useNavigate } from "react-router-dom";
import {
  getRecipe,
  handleRecipeDecision,
} from "../../services/recipes/recipes";
import AlertDismissible from "../../Components/Alerts/AlertDismissible";
import { AlertContext } from "../../Contexts/AlertContext";
import ModalCustomized from "../../Components/Modal";

const RecipeAdministration = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [indexPage, setIndexPage] = useState(0);
  const [recipe, setRecipe] = useState();
  const [recipeState, setRecipeState] = useState("");
  const [observation, setObservation] = useState("");
  const [disableFields, setDisableFields] = useState(false);
  const recipeIssue = useRef(null);
  const optionsHeader = ["Información", "Resolución"];
  const navigate = useNavigate();
  const widthTab = `${(1 / optionsHeader.length) * 100}%`;
  const [alert, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const handleTabChange = (idxOfCurrentTab) => {
    setIndexPage(idxOfCurrentTab);
  };

  const { recipeId } = useParams();
  const { setSuccessAlert, setAlertMsg } = useContext(AlertContext);
  useEffect(() => {
    getRecipe(recipeId)
      .then(({ response }) => {
        if (response.recipeIssue.recipe) {
          setRecipe(response.recipeIssue.recipe);
          recipeIssue.current = response.recipeIssue;
        } else throw "Error al obtener la receta.";

        if (response.recipeState !== null) {
          setDisableFields(true);
          setRecipeState(response.recipeState);
          setObservation(response.observation);
        }
      })
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
  }, []);

  const openConfirmationDialog = () => {
    if (checkBeforeSaving()) {
      setShowDialog(true);
    }
  };

  const checkBeforeSaving = () => {
    const validStates = [0, 1];

    if (!validStates.includes(recipeState)) {
      setAlert(true);
      setErrorMsg(
        "Campo de decisión vacío, debe indicarle al usuario cuál es la decisión que se tomó."
      );
      return false;
    }

    if (observation.trim().length === 0) {
      setAlert(true);
      setErrorMsg(
        "Campo de observaciones vacío, debe indicarle al usuario cuál es la razón de la decisión que se tomó."
      );
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!recipe) {
      navigate(-1);
      return;
    }

    handleRecipeDecision(recipeIssue.current, observation, recipeState)
      .then((res) => {
        if (res === 200) {
          setAlertMsg(
            recipeState === 0
              ? "La receta se aprobó con éxito"
              : "La receta se rechazó con éxito"
          );
          setSuccessAlert(true);
          navigate(-1);
        } else throw "Error.";
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="recipe-admin-container">
      {isLoading && (
        <div className="loading-centered-container">
          <Spinner
            animation="border"
            variant="success"
            className="loading-centered"
          />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="header-modal-title">{recipe && recipe.name}</div>
          <div className="options-tab-container">
            {optionsHeader.map((each, idx) => (
              <MyTab
                key={idx}
                btnText={each}
                tabBtnPressed={(idxOfPressed) => handleTabChange(idxOfPressed)}
                pressed={idx === indexPage}
                btnIndex={idx}
                widthTab={widthTab}
              />
            ))}
          </div>
          {indexPage === 0 ? (
            recipe ? (
              <RecipeInformationPage recipe={recipe}></RecipeInformationPage>
            ) : (
              <></>
            )
          ) : (
            <RecipeResolution
              recipeState={recipeState}
              setRecipeState={setRecipeState}
              observation={observation}
              setObservation={setObservation}
              disabledFields={disableFields}
            />
          )}

          <div className="btn-group-container-modal">
            {!disableFields ? (
              <>
                <button
                  className="btn-container-modal btn-cancel"
                  onClick={() => navigate(-1)}
                >
                  CANCELAR
                </button>
                <button
                  className="btn-container-modal btn-save"
                  onClick={openConfirmationDialog}
                >
                  GUARDAR
                </button>
              </>
            ) : (
              <button
                className="btn-container-go-back btn-cancel"
                onClick={() => navigate(-1)}
              >
                VOLVER
              </button>
            )}
          </div>
          <div className="alert-container">
            <AlertDismissible
              value={alert}
              onChange={(val) => setAlert(val)}
              alertType="danger"
              buttonType="outline-danger"
              text={errorMsg}
            />
          </div>
        </>
      )}
      <ModalCustomized
        show={showDialog}
        title={`¿Está seguro que desea ${
          recipeState === 0 ? "aprobar" : "rechazar"
        } la receta?`}
        okTest={"Eliminar"}
        handleClose={() => setShowDialog(false)}
        handleSave={handleSave}
        dialogClass="dialog-container-center-horizontal"
        centered
      />
    </div>
  );
};

export default RecipeAdministration;
