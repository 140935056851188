import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export const ProtectedRoute = ({ children, permissionRequired}) => {

  const [isAbleToNavigate, setIsAbleToNavigate] = useState(false);

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if(permissionRequired){
      if(isAdmin === "true"){
        setIsAbleToNavigate(true);
      }
    }
  }, []);

  if(isAbleToNavigate){
    return children
  }else{
    return <div></div>
  }
}

ProtectedRoute.propTypes = {
  children: PropTypes.element,
  permissionRequired: PropTypes.bool
}