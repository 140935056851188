import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
export default function AlertDismissible({ value: show, onChange: setShow, alertType, buttonType, text }) {

    return (
        <>
            <Alert show={show} variant={alertType} >
                <p>{text}</p>
                <hr />
                <div className="d-flex justify-content-center">
                    <Button onClick={() => setShow(false)} variant={buttonType}>
                        Entendido
                    </Button>
                </div>
            </Alert>
        </>
    );
}

AlertDismissible.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    alertType: PropTypes.string.isRequired,
    buttonType: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}; 