/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Typography,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import "./FullScreenImageModal.css";

function FullScreenImageModal(props) {
  const { image, isOpen, closeModal } = props;

  return (
    <Modal
      open={isOpen}
      onBackdropClick={closeModal}
      className="image-modal-styles"
      disableAutoFocus
    >
      <img src={image} className="img-styles"></img>
    </Modal>
  );
}

export default FullScreenImageModal;
