import React, { useState } from "react";
import RecipeTable from "../../Components/Table/RecipeTable";
import MyHeader from "../../Components/MyHeader/Myheader";
import RecipeSearchBar from "../../Components/SearchBar/RecipeSearchBar";

function RecipeManagement() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dataFilter, setDataFilter] = useState();

  return (
    <div className="recipe-admin-container">
      <MyHeader selectedTab={(val) => setSelectedTab(val)} />
      <RecipeSearchBar
        getDataFilter={(dataFilter) => setDataFilter(dataFilter)}
      />
      <RecipeTable selectedTab={selectedTab} dataFilter={dataFilter} />
    </div>
  );
}

export default RecipeManagement;
