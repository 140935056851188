import { axiosInstance as axios } from '../api/axios';

async function login(username, password) {
    let status;

    await axios.post('auth/token',
        {
            username: username,
            password: password
        })
        .then((res) => {
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('lastName', res.data.lastName);
            localStorage.setItem('isAdmin', res.data.isAdmin);
            localStorage.setItem('username', res.data.username);
            status = res.status
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status) {
                    status = error.response.status
                };
            }
        })
    return status
}

function logout() {
    localStorage.clear();
    window.location.replace('/');
}

export { login, logout };