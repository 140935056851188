/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { getUsers, modifyUser } from "../../services/users/users";
import { Spinner } from "react-bootstrap";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import "./UserTable.css";

const boxStyle = {
  height: "100vh",
  width: "100%",
  padding: "0px 20px 20px",
};

function UserTable() {
  const [rows, setRows] = useState([]);
  const [selectedField, setSelectedField] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      field: "username",
      headerName: "Nombre de usuario",
      flex: 1,
      headerClassName: "custom-table--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "familyName",
      headerName: "Nombre de familia",
      flex: 1,
      headerClassName: "custom-table--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "custom-table--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "state",
      headerName: "Estado",
      flex: 0.6,
      headerClassName: "custom-table--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "PK",
      headerName: "Acciones",
      flex: 0.6,
      headerClassName: "custom-table--header",
      headerAlign: "center",
      renderCell: (params) => {
        const [isLoadingRow, setIsLoadingRow] = useState(false);
        return isLoadingRow ? (
          <div className="loading-centered-container">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="action-column-item-container">
            <p
              className="action-column-item"
              onClick={() => {
                setIsLoadingRow(true);
                modifyUser(
                  params.row.PK,
                  params.row.state === "Habilitado" ? true : false
                )
                  .then((res) => {
                    setRows(res.response);
                    setIsLoadingRow(false);
                  })
                  .catch(console.error);
              }}
            >
              {params.row.state === "Habilitado" ? "Deshabilitar" : "Habilitar"}
            </p>
          </div>
        );
      },
    },
  ];

  const handleSelectedField = (val) => {
    setSelectedField(val.target.value);
  };

  const handleChangeInputSearch = (ev) => {
    setSearchValue(ev.target.value);
  };

  useEffect(() => {
    getUsers()
      .then((res) => {
        setRows(res.response);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  const filterRows = () => {
    return rows.filter((eachRow) => {
      switch (selectedField) {
        case 0: {
          if (
            eachRow.username.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return eachRow;
          } else {
            return;
          }
        }
        case 1: {
          if (
            eachRow.familyName.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return eachRow;
          } else {
            return;
          }
        }
        case 2: {
          if (eachRow.email.toLowerCase().includes(searchValue.toLowerCase())) {
            return eachRow;
          } else {
            return;
          }
        }
        default: {
          return eachRow;
        }
      }
    });
  };

  return isLoading ? (
    <div className="loading-centered-container">
      <Spinner
        animation="border"
        variant="success"
        className="loading-centered"
      />
    </div>
  ) : (
    <>
      <div className="filter-fields-container">
        <h1 className="search-title">Filtrar resultados:</h1>
        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
          <InputLabel color="warning">Campo de búsqueda</InputLabel>
          <Select
            value={selectedField}
            label="Campo de búsqueda"
            placeholder="Campo de búsqueda"
            color="warning"
            onChange={handleSelectedField}
          >
            <MenuItem value={0}>Nombre de usuario</MenuItem>
            <MenuItem value={1}>Nombre de familia</MenuItem>
            <MenuItem value={2}>Email</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          id="outlined-search"
          label="Buscar"
          type="search"
          color="warning"
          value={searchValue}
          onChange={handleChangeInputSearch}
          disabled={selectedField !== "" ? false : true}
        />
      </div>
      <Box sx={boxStyle}>
        <DataGrid
          getRowId={(row) => row.PK}
          rows={filterRows()}
          columns={columns}
          autoPageSize
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          localeText={{
            noRowsLabel: "No hay usuarios.",
          }}
          getRowClassName={() => "custom-table--row"}
          sx={{
            "& .MuiDataGrid-cell:hover": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#8ce3af",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0 auto",
            },
            "& .MuiDataGrid-virtualScrollerContent": {
              backgroundColor: "#cef3dd",
            },
            "& .MuiDataGrid-cell:active": {
              outline: "none",
              border: 0,
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
              border: 0,
            },
          }}
        />
      </Box>
    </>
  );
}

export default UserTable;
