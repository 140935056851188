import axios from "axios";
import { logout } from "../Auth/sessionManagement";

const BACKEND_API =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/web"
    : "https://wx0itbya3f.execute-api.us-east-1.amazonaws.com/web";

const axiosInstance = axios.create({
  baseURL: BACKEND_API,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.access_token) {
      localStorage.setItem("jwt", response.data.access_token);
    }

    return response;
  },
  (error) => {
    error.response.status === 401
      ? logout()
      : console.log(error.response.status);

    return Promise.reject(error);
  }
);

export { axiosInstance };
