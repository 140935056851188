import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import "./ReasonsReport.css";

const ReasonsReport = ({ reasons }) => {
  return (
    <div className="reasons-report-container">
      <ol className="reason-card-list">
        {reasons.map((reason, idx) => {
          return (
            <li key={`${idx}${reason.sk}`} className="reason-card">
              <div>
                <p>
                  {`"${reason.description}"`}
                  <br />
                  <span className="datetime-txt">{reason.issuedAt}</span>
                </p>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default ReasonsReport;

ReasonsReport.propTypes = {
  currentWord: PropTypes.string.isRequired,
  reasons: PropTypes.array.isRequired,
};
