const ES_VAL = {
    "name": "es",
    "options": {
        "months": [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        "shortMonths": [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
        ],
        "days": [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ],
        "shortDays": ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        "toolbar": {
            "exportToSVG": "Descargar SVG",
            "exportToPNG": "Descargar PNG",
            "exportToCSV": "Descargar CSV",
            "menu": "Menu",
            "selection": "Seleccionar",
            "selectionZoom": "Seleccionar Zoom",
            "zoomIn": "Aumentar",
            "zoomOut": "Disminuir",
            "pan": "Navegación",
            "reset": "Reiniciar Zoom"
        }
    }
}

const options = {
    chart: {
        defaultLocale: 'es',
        fontFamily: 'Raleway',
        id: 'accountCreatedAndEliminated',
        locales: [ES_VAL],
        type: 'line',
        toolbar: {
            autoSelected: 'pan',
            show: false
        },
    },
    colors: ['#426b69', '#ec4d37'],
    legend: {
        labels: {
            colors: '#273837',
        },
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 0
    },
    title: {
        align: 'center',
        margin: 20,
        style:{
            fontSize:'18px',
            color:  '#273837'
        },
        text: 'Cuentas creadas y eliminadas',
    },
    xaxis: {
        type: 'datetime',
        labels: {
            style: {
                colors: '#273837',
            },
        },
    },
    yaxis: {
        labels: {
            formatter: function (val) {
                return val
            },
            style: {
                colors: '#273837',
            },
        },
    },
}

const optionsLine = {

    legend: {
        labels: {
            colors: '#273837',
        },
    },
    chart: {
        brush: {
            target: 'accountCreatedAndEliminated',
            enabled: true
        },
        defaultLocale: 'es',
        fontFamily: 'Raleway',
        id: 'accountCreatedAndEliminated1',
        locales: [ES_VAL],
        selection: {
            enabled: true,
        },
        type: 'area',
    },
    colors: ['#426b69', '#ec4d37'],
    fill: {
        type: 'gradient',
        gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
        }
    },
    xaxis: {
        labels: {
            style: {
                colors: '#273837',
            },
        },
        type: 'datetime',
        tooltip: {
            enabled: false
        }
    },
    yaxis: {
        labels: {
            formatter: function (val) {
                return val
            },
            style: {
                colors: '#273837',
            },
        },
        tickAmount: 2,
    }
}

const optionsBar = {
    chart: {
        defaultLocale: 'es',
        fontFamily: 'Raleway',
        locales: [ES_VAL],
    },
    dataLabels: {
        enabled: true,
    },
    fill: {
        colors: ['#ec4d37'],
        type: 'gradient',
        gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            opacityFrom: 0.85,
            opacityTo: 1,
        },
    },
    plotOptions: {
        bar: {
            borderRadius: 15,
        }
    },
    title: {
        align: 'center',
        margin: 20,
        style:{
            fontSize:'18px',
            color:  '#273837'
        },
        text: 'Funcionalidades utilizadas',
    },
    tooltip: {
        x: {
            formatter: function (val) {
                switch (val) {
                    case "Rec. Comunidad":
                        return "Recetas de la comunidad creadas"
                    case "Votaciones ráp.":
                        return "Votaciones rápidas creadas"
                    case "Listas":
                        return "Listas de compras creadas"
                    case "Menús":
                        return "Menús creados"
                    case "Recetas":
                        return "Mis recetas creadas"
                }
            }
        },
    },
    xaxis: {
        labels: {
            rotate: -45,
            style: {
                fontSize: '11px',
                colors: '#273837',
            },
        },
        type: 'category',
    },
    yaxis: {
        labels: {
            style: {
                colors: '#273837',
            },
        },
    },
}
const optionsHorizontalBar = {
    chart: {
        defaultLocale: 'es',
        fontFamily: 'Raleway',
        locales: [ES_VAL],
    },
    dataLabels: {
        enabled: true,
    },
    fill: {
        colors: ['#ec4d37'],
        type: 'gradient',
        gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            opacityFrom: 0.85,
            opacityTo: 1,
        },
    },
    plotOptions: {
        bar: {
            borderRadius: 15,
            horizontal: true,
        }
    },
    title: {
        align: 'center',
        margin: 20,
        style:{
            fontSize:'18px',
            color:  '#273837'
        },
        text: 'Estados de listas de compras',
    },
    tooltip: {
        x: {
            formatter: function (val) {
                switch (val) {
                    case "Editando":
                        return "Listas de compras en edición"
                    case "Activadas":
                        return "Listas de compras activadas"
                    case "Finalizadas":
                        return "Listas de compras finalizadas"
                }
            }
        },
        y: {
            formatter: function (val) {
                return val
            }
        }
    },
    xaxis: {
        labels: {
            style: {
                colors: '#273837',
            },
        },
        type: 'numeric',
    },
    yaxis: {
        labels: {
            style: {
                colors: '#273837',
                fontSize: '12px',
            },
        }
    },
}
const optionsPie = {
    chart: {
        defaultLocale: 'es',
        fontFamily: 'Raleway',
        locales: [ES_VAL],
    },
    colors: ['#EEE84F', '#8CE3AF', '#EC4D37'],
    dataLabels: {
        enabled: true,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {

            return w.config.labels[seriesIndex] + ":  " + parseFloat(value).toFixed(2) + "%"
        },
        style: {
            colors: ['#273837'],
            fontSize: '16px',
        },
        dropShadow: {
            enabled: false
        }
    },
    labels: ["Pendientes", "Aceptadas", "Rechazadas"],
    legend: {
        fontSize: '12px',
        position:'bottom'
    },
    title: {
        align: 'center',
        margin: 20,
        style:{
            fontSize:'18px',
            color:  '#273837'
        },
        text: 'Solicitudes de recetas de la comunidad',
    },
    tooltip: {
        fillSeriesColor: false,
        y: {
            title: {
                formatter: function (val) {
                    switch (val) {
                        case "Pendientes":
                            return "Solicitudes de recetas de la comunidad pendientes"
                        case "Aceptadas":
                            return "Solicitudes de recetas de la comunidad aceptadas"
                        case "Rechazadas":
                            return "Solicitudes de recetas de la comunidad rechazadas"
                    }
                }
            }
        }
    },
    xaxis: {
        labels: {
            style: {
                colors: '#273837',
            },
        },
    },
    yaxis: {
        labels: {
            style: {
                colors: '#273837',
            },
        }
    },
}
export { options, optionsLine, optionsBar, optionsHorizontalBar, optionsPie }