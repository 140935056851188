import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from "./Contexts/AuthContext";
import MainApp from "./Views/MainApp/MainApp";
import Login from "./Views/Login/Login";
import ModeratorSignUp from "./Views/ModeratorSignUp";

export default function App() {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("jwt") !== null
  );
  const moderatorSignUp = window.location.pathname === "/moderator-sign-up";

  return (
    <BrowserRouter basename="/">
      <AuthContext.Provider value={{ setLoggedIn }}>
        {moderatorSignUp ? (
          <ModeratorSignUp />
        ) : !loggedIn ? (
          <Login />
        ) : (
          <MainApp />
        )}
      </AuthContext.Provider>
    </BrowserRouter>
  );
}
