/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./MyTab.css";

function MyTab(props) {
  const { btnText, tabBtnPressed, pressed, btnIndex, widthTab } = props;

  return (
    <button
      className={pressed ? "btn-tab pressed-btn" : "btn-tab unpressed-btn"}
      onClick={() => tabBtnPressed(btnIndex)}
      style={{ width: widthTab }}
    >
      {btnText}
    </button>
  );
}

export default MyTab;
