import React from "react";
import UserTable from "../../Components/Table/UserTable";

function Accounts() {
  return (
    <div className="recipe-admin-container">
      <UserTable />
    </div>
  );
}

export default Accounts;
