/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import "./RecipeTable.css";
import { getRecipes } from "../../services/recipes/recipes";
import moment from "moment";

const dateSortComparator = (v1, v2) => {
  if (!v2) {
    return moment(v1, "DD/MM/YYYY") - moment("01/01/1970", "DD/MM/YYYY");
  } else if (!v1) {
    return moment("01/01/1970", "DD/MM/YYYY") - moment(v2, "DD/MM/YYYY");
  }
  return moment(v1, "DD/MM/YYYY") - moment(v2, "DD/MM/YYYY");
};

const columns = [
  {
    field: "recipeName",
    headerName: "Nombre",
    flex: 1,
    headerClassName: "custom-table--header",
    headerAlign: "center",
  },
  {
    field: "issuerUsername",
    headerName: "Usuario",
    flex: 0.8,
    headerClassName: "custom-table--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "creationDate",
    headerName: "Fecha de creación",
    flex: 0.8,
    headerClassName: "custom-table--header",
    headerAlign: "center",
    align: "center",
    sortComparator: dateSortComparator,
  },
  {
    field: "acceptedDate",
    headerName: "Fecha de aceptación",
    flex: 0.9,
    headerClassName: "custom-table--header",
    headerAlign: "center",
    align: "center",
    sortComparator: dateSortComparator,
  },
  {
    field: "rejectionDate",
    headerName: "Fecha de rechazo",
    flex: 0.8,
    headerClassName: "custom-table--header",
    headerAlign: "center",
    align: "center",
    sortComparator: dateSortComparator,
  },
  {
    field: "recipeState",
    headerName: "Estado",
    flex: 0.6,
    headerClassName: "custom-table--header",
    headerAlign: "center",
    align: "center",
  },
];

const boxStyle = {
  height: "100vh",
  width: "100%",
  padding: "20px",
};

export default function RecipeTable({ selectedTab, dataFilter }) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getRecipes()
      .then((res) => {
        setRows(res.response);
      })
      .catch(console.error);
  }, []);

  const filterRows = (idx) => {
    return rows.filter((eachRecipeIssue) => {
      switch (idx) {
        case 0: {
          if (dataFilter) {
            if (!checkIsInPeriod(eachRecipeIssue)) {
              return false;
            }
          }
          return eachRecipeIssue;
        }
        case 1: {
          if (dataFilter) {
            if (!checkIsInPeriod(eachRecipeIssue)) {
              return false;
            }
          }
          return eachRecipeIssue.recipeState === "Pendiente";
        }
        case 2: {
          if (dataFilter) {
            if (!checkIsInPeriod(eachRecipeIssue)) {
              return false;
            }
          }
          return eachRecipeIssue.recipeState === "Rechazada";
        }
        case 3: {
          if (dataFilter) {
            if (!checkIsInPeriod(eachRecipeIssue)) {
              return false;
            }
          }
          return eachRecipeIssue.recipeState === "Aceptada";
        }
      }
    });
  };

  const checkIsInPeriod = (eachRecipeIssue) => {
    const start = new Date(dataFilter.dateFrom);
    const end = new Date(dataFilter.dateUntil);
    const dateToCheck = new Date(
      dataFilter.kindOfDate === 0
        ? moment(eachRecipeIssue.creationDate, "DD/MM/YYYY")
        : dataFilter.kindOfDate === 1
        ? moment(eachRecipeIssue.acceptedDate, "DD/MM/YYYY")
        : moment(eachRecipeIssue.rejectionDate, "DD/MM/YYYY")
    );

    if (dateToCheck >= start && dateToCheck <= end) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box sx={boxStyle}>
      <DataGrid
        rows={rows ? filterRows(selectedTab) : []}
        columns={columns}
        /*  pageSize={10}
        rowsPerPageOptions={[5, 10, 15, 20, 25]} */
        autoPageSize
        onRowClick={({ row }) => navigate(`${row.id}`)}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        localeText={{
          noRowsLabel: "No hay recetas.",
        }}
        getRowClassName={() => "custom-table--row"}
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#8ce3af",
          },
          "& .MuiTablePagination-displayedRows": {
            margin: "0 auto",
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            backgroundColor: "#cef3dd",
          },
          "& .MuiDataGrid-cell:active": {
            outline: "none",
            border: 0,
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
            border: 0,
          },
        }}
      />
    </Box>
  );
}
