import React, { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Spinner from 'react-bootstrap/Spinner'
import { Button, Row,  Form, InputGroup } from "react-bootstrap";
import { send_moderator_data, check_token_invitation } from "../../services/moderatorSignUp";
import { useSearchParams } from "react-router-dom";
import b64DecodeUnicode from "../../api/utils";
import ModalCustomized from "../../Components/Modal";
import "./style.css";

export default function ModeratorSignUp(props) {
  const [validated, setValidated] = useState(false)
  const [firstEye, setFirstEye] = useState(false)
  const [secondEye, setSecondEye] = useState(false)
  const [state, setState] = useState({})
  const [password, setPassword] = useState('')
  const [secondPassword, setSecondPassword] = useState('')
  const [passwordDuplicated, setPasswordDuplicated] = useState(false)
  const [lengthValidation, setLengthValidation] = useState(false)
  const [alfaNumericValidation, setAlfaNumericValidation] = useState(false)
  const [upcDownValidation, setUpcDownValidation] = useState(false)
  const regexLength = /^.{10,}$/;
  const regexSymbols = /^(.*[$@#$!%*?&])+/;
  const regexUpc = /^(.*[A-Z])+/;
  const regexDownc = /^(.*[a-z])+/;
  const [searchParams, setSearchParams] = useSearchParams();
  const [tokenExpired, setTokenExpired] = useState(false);
  const [isloading, setLoading] = useState(null);
  const [errorCreating, setErrorCreating] = useState(false)
  const [successCreating, setSuccessCreating] = useState(false)
  let emailGetter;
  

  const handleSubmit = (e) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      if(!passwordDuplicated && lengthValidation && alfaNumericValidation && upcDownValidation){
        event.preventDefault();
        setState({username: form.usernameGroup.value, password: form.passwordGroup.value, secPassword: form.password2Group.value})
        send_moderator_data({username: form.usernameGroup.value, password: form.passwordGroup.value, email: b64DecodeUnicode(searchParams.get('token'))})
        .then(response => {
          if(response){
            setSuccessCreating(true)
            setTimeout(() => {
              setSuccessCreating(false)
              window.location.replace('/');
            },2000)
            localStorage.clear();
          }else{
            setErrorCreating(true)
          }
        })
      }else{
        event.preventDefault();
        event.stopPropagation();
        setValidated(false)
        return
      }
    }
    setValidated(true);
  }
  const handleFirstEye = () => {
    setFirstEye(prevState => !prevState)
  }
  const handleSecondEye = () => {
    setSecondEye(prevState => !prevState)
  }
  const handleOnChangeInput = (e) => {
    setSecondPassword(e.target.value)
    if(password === e.target.value){
      setPasswordDuplicated(false)  
    }else{
      setPasswordDuplicated(true)  
    }
  }
  const handleOnBlur = (e) => {
    setPassword(e.target.value)
    if(passwordDuplicated){
      setPasswordDuplicated(true)  
    }else{
      setPasswordDuplicated(false)  
    }
  }
  const handlePassword = (e) => {
    regexLength.test(e.target.value) ? setLengthValidation(true) : setLengthValidation(false)
    regexSymbols.test(e.target.value) ? setAlfaNumericValidation(true) : setAlfaNumericValidation(false)
    regexUpc.test(e.target.value) && regexDownc.test(e.target.value) ? setUpcDownValidation(true) : setUpcDownValidation(false)

    if(secondPassword === e.target.value){
      setPasswordDuplicated(false)  
    }else{
      setPasswordDuplicated(true)  
    }
    
  }
  const handleErrorCreating = () => {
    setErrorCreating(false)
  }
  const handleSuccessCreating = () => {
    setSuccessCreating(false)
  }

  useEffect(() => {
    emailGetter = b64DecodeUnicode(searchParams.get('token'))
    setLoading(true)
    if(searchParams.get('token')){
      check_token_invitation(emailGetter).then(response => {
        setLoading(false)
        if(response){
          setTokenExpired(true)
        }
      })
    }else{
      //Navigate con mensaje que el token está expirado
      setTokenExpired(false)
      setLoading(true)
    }
  },[])

  if(!isloading){
  return (
      tokenExpired ?
      (<div className="M-SigUp-Container">
        <div className="M-SignUp-Form ">
          <Row>
            <h2>Ingrese sus datos</h2>
          </Row>
          <hr />
          <Row className="M-SignUp">
            <Form validated={validated} onSubmit={(e) => handleSubmit(e)}>
              <Form.Group className="mb-3" controlId="usernameGroup">
                <Form.Label>Usuario</Form.Label>
                <Form.Control placeholder='Ingrese nombre de usuario' type="text" required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="passwordGroup">
                <Form.Label>Contraseña</Form.Label>
                <InputGroup>
                  <Form.Control placeholder='Ingrese contraseña' type={firstEye ? 'text' : 'password'} required onBlur={handleOnBlur} onChange={(e) => handlePassword(e)} />
                  <span className="input-group-text eye" onClick={handleFirstEye}>{!firstEye ? <VisibilityIcon /> : <VisibilityOffIcon />}</span>
                </InputGroup>
                <div className="M-SignUp-Password-Description">
                  <ul>
                    <li>
                      <span className={lengthValidation ? "M-SignUp-Password-Text active" : "M-SignUp-Password-Text deactive"}>Longitud mínima de 10 carácteres</span>
                    </li> 
                    <li>
                      <span className={alfaNumericValidation ? "M-SignUp-Password-Text active" : "M-SignUp-Password-Text deactive"}>Simbolos ($@#$!%*?&)</span>
                    </li>
                    <li>
                      <span className={upcDownValidation ? "M-SignUp-Password-Text active" : "M-SignUp-Password-Text deactive"}>Mayúsculas y minúsculas</span>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password2Group">
                <Form.Label>Confirme su contraseña</Form.Label>
                <InputGroup>
                  <Form.Control placeholder='Ingrese nuevamente su contraseña' type={secondEye ? 'text' : 'password'} required onChange={(e) => handleOnChangeInput(e)}/>
                  <span className="input-group-text eye" onClick={handleSecondEye}>{!secondEye ? <VisibilityIcon /> : <VisibilityOffIcon />}</span>
                </InputGroup>
                {
                  passwordDuplicated ?
                  <span style={{color: 'red'}}>Las contraseñas no coinciden</span>
                  :
                  <></>
                }
              </Form.Group>
              <div className="M-SignUp-Container-Submit">
                <Button id="M-SignUp-Submit" type="submit">Enviar datos</Button>
              </div>
            </Form>
          </Row>
        </div>
        <ModalCustomized 
          show={errorCreating} 
          message={'Error'} 
          handleSave={handleErrorCreating}
          title={'Ha surgido un problema, intente nuevamente'}
          okText={'Aceptar'}
          deny={false}
        />
        <ModalCustomized 
          show={successCreating} 
          message={'¡Se ha creado su cuenta exitosamente!'} 
          handleSave={handleSuccessCreating}
          title={'Bienvenido'}
          ok={false}
          deny={false}
        />
      </div>)
      : 
      (<div>
        <div className="M-SigUp-Container">
          <div className="M-SignUp-Form ">
            <p className="M-SignUp-Deny-Text">Acceso denegado. Token expirado</p>
          </div>
        </div>
      </div>)
  );
  }else{
    return(
    <div className="M-SigUp-Container">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Cargando...</span>
      </Spinner>
    </div>)
  }
}
