import { axiosInstance as axios } from '../../api/axios';

function getHeaders(){
    let token = localStorage.getItem('jwt')
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`}
    return headers
}
async function send_invitation(data) {
    let status;
    const header = getHeaders();
    await axios.post('admin/send_invitation',
    {          
        name: data.name,
        lastName: data.lastName,
        email: data.email
    },
    {
        headers:header
    })
    .then((res) => {
        status = res.status 
        console.log(res)
    })
    .catch((error) => {
        if (error.response) {
            if(error.response.status) {
                status = error.response.status 
            };
        }  
    })
    return status      
}

async function get_moderators_admins() {
    let status;
    let moderators;
    const header = getHeaders();
    await axios.get('admin/get_moderators_admins',
    {
        headers:header
    })
    .then((res) => {
        status = res.status 
        moderators = res
    })
    .catch((error) => {
        if (error.response) {
            if(error.response.status) {
                status = error.response.status 
            };
        }  
    })
    return moderators      
}

async function delete_moderator(email) {
    let status;
    const header = getHeaders();
    await axios.post('admin/delete_moderator',
    {          
        email: email
    },
    {
        headers:header
    })
    .then((res) => {
        status = res.status 
        console.log(res)
    })
    .catch((error) => {
        if (error.response) {
            if(error.response.status) {
                status = error.response.status 
            };
        }  
    })
    return status      
}


export { send_invitation, get_moderators_admins, delete_moderator };