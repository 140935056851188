import { axiosInstance as axios } from '../../api/axios';

async function check_token_invitation(email) {
  let status;

  await axios.post('admin/check_token',
  {          
      email: email,
  })
  .then((res) => {
      status = res.status 
  })
  .catch((error) => {
      if (error.response) {
          if(error.response.status) {
              status = error.response.status 
          };
      }  
  })
  if(status == 202 || status == 400){
    return false
  }else{
    return true
  }   
} 

async function send_moderator_data({username, password, email}) {
    let status;
  
    await axios.post('admin/send_moderator_data',
    {          
        username: username,
        password: password,
        email: email
    })
    .then((res) => {
        status = res.status 
    })
    .catch((error) => {
        if (error.response) {
            if(error.response.status) {
                status = error.response.status 
            };
        }  
    })
    if(status == 202 || status == 400 || status == 500){
      return false
    }else{
      return true
    }   
}

export { send_moderator_data, check_token_invitation };