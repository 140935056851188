import React, { useState } from "react";
import "./MainApp.css";
import { Routes, Route, Navigate } from "react-router-dom";
import SideNav from "../../Components/SideNav";
import Dashboard from "../Dashboard/Dashboard"
import Administration from "../Administration";
import Report from "../Reports/Reports";
import RecipeAdministration from "../Recipes/RecipeAdministration";
import RecipeManagement from "../Recipes/RecipeManagement";
import { AlertContext } from "../../Contexts/AlertContext";
import AlertDismissible from "../../Components/Alerts/AlertDismissible";
import Accounts from "../Accounts/Accounts";
import { ProtectedRoute } from "../../Components/ProtectedRoute/ProtectedRoute";

export default function MainApp() {
  const [alertMsg, setAlertMsg] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const itemsForNavigationSide = [
    {
      name: "Tablero",
      to: "/dashboard",
    },
    {
      name: "Gestionar recetas",
      to: "/recipes",
    },
    {
      name: "Administradores",
      to: "/administrators",
    },
    {
      name: "Deshabilitar cuentas",
      to: "/accounts",
    },
    {
      name: "Motivos de eliminación de cuenta",
      to: "/reports",
    },
  ];

  return (
    <div className="App">
      <div className="Container">
        <SideNav items={itemsForNavigationSide}></SideNav>
        <AlertContext.Provider value={{ setSuccessAlert, setAlertMsg }}>
          <div className="View-Container">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/recipes" element={<RecipeManagement />} />
              <Route path="/moderators" element={
                <ProtectedRoute permissionRequired={true}>
                  <Administration />
                </ProtectedRoute>
              } />
              <Route path="/reports" element={<Report />} />
              <Route
                path="/recipes/:recipeId"
                element={<RecipeAdministration />}
              />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
            <div className="root-alert-container">
              <AlertDismissible
                value={successAlert}
                onChange={(val) => setSuccessAlert(val)}
                alertType="success"
                buttonType="outline-success"
                text={alertMsg}
              />
            </div>
          </div>
        </AlertContext.Provider>
      </div>
    </div>
  );
}
