import React, { useState, useRef } from "react";
import TableCustomized from "../../Components/Table";
import ModalCustomized from "../../Components/Modal";
import { send_invitation } from "../../services/administrators";
import { Button, Form } from "react-bootstrap";
import "./style.css";

export default function Administration() {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({ name: "", lastName: "", email: "" });
  const [errorsInviting, setErrorsInviting] = useState(false);
  const [successInviting, setSuccessInviting] = useState(false);
  const [loadingShow, setLoadingShow] = useState(false);
  const formRef = useRef();

  const handleClose = () => {
    setShow(false);
  };
  const handleErrorsInviting = () => {
    setErrorsInviting(false);
  };
  const handleSuccessInviting = () => {
    setSuccessInviting(false);
  };
  const handleSave = () => {
    setShow(false);
    setLoadingShow(true);
    send_invitation(state).then((response) => {
      if (response != "201") {
        setLoadingShow(false);
        setErrorsInviting(true);
      } else {
        setTimeout(() => {
          formRef.current.reset();
          formRef.current.className = "";
        });
        setLoadingShow(false);
        setSuccessInviting(true);
      }
    });
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setShow(true);
      setState({
        name: form.nameGroup.value,
        lastName: form.lastNameGroup.value,
        email: form.emailGroup.value,
      });
    }
    setValidated(true);
  };

  return (
    <div className="recipe-admin-container Administration-Container">
      <div className="Administration-Search-Container">
        <Form ref={formRef} validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="nameGroup">
            <h4>Ingrese los datos de la persona a invitar</h4>
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" required />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese el nombre del invitado.
            </Form.Control.Feedback>
            <Form.Control.Feedback>Perfecto!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="lastNameGroup">
            <Form.Label>Apellido</Form.Label>
            <Form.Control type="text" required />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese el apellido del invitado.
            </Form.Control.Feedback>
            <Form.Control.Feedback>Perfecto!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="emailGroup">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" required />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese un email válido.
            </Form.Control.Feedback>
            <Form.Control.Feedback>Perfecto!</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" id="Administration-Submit">
            Enviar invitación
          </Button>
        </Form>
      </div>
      <hr />
      <h4>Lista de moderadores</h4>
      <div className="Administration-Table-Container">
        <TableCustomized className="Administration-Table" />
      </div>
      <ModalCustomized
        show={show}
        message={
          "¿Estas seguro que deseas invitar a: " +
          state.name +
          " " +
          state.lastName +
          " / " +
          state.email +
          "?"
        }
        handleClose={handleClose}
        handleSave={handleSave}
        title={"Invitación"}
        okText={"Enviar"}
        denyText={"Cerrar"}
      />
      <ModalCustomized
        show={errorsInviting}
        title={"Error"}
        handleSave={handleErrorsInviting}
        message={
          "La persona que intentas invitar, ya es moderador o su invitación está pendiente para aceptar"
        }
        okText={"Aceptar"}
        deny={false}
      />
      <ModalCustomized
        show={successInviting}
        message={"La invitación se ha enviado satisfactoriamente !"}
        handleSave={handleSuccessInviting}
        title={"Email enviado"}
        okText={"Aceptar"}
        deny={false}
      />
      <ModalCustomized
        show={loadingShow}
        message={"Por favor aguarde unos minutos..."}
        title={"Enviando invitación"}
        ok={false}
        deny={false}
      />
    </div>
  );
}
