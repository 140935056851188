import React, { useEffect, useState } from "react";
import ReactWordcloud from "react-wordcloud";
import { Spinner } from "react-bootstrap";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
import "./Reports.css";
import { getReasonsReport } from "../../services/reports/reports";
import ReasonsReport from "../../Components/ReasonsReport/ReasonsReport";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

const Report = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [wordCloud, setWordCloud] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [currentWord, setCurrentWord] = useState("");
  const options = {
    rotations: 0,
    rotationAngles: [0, 0],
    fontSizes: [20, 30],
    colors: ["#273837"],
    fontWeight: "bold",
    fontFamily: "Raleway",
  };

  const handleClickWordSearch = (word) => {
    setCurrentWord(word.text);
  };

  const refreshList = () => {
    setCurrentWord("");
  };

  const callbacks = {
    onWordClick: handleClickWordSearch,
    getWordColor: (word) => (word.text === currentWord ? "#ec4d37" : "#273837"),
  };

  useEffect(() => {
    getReasonsReport().then((res) => {
      setWordCloud(res.response.wordCloud);
      setReasons(res.response.reasons);
      setIsLoading(false);
    });
  }, []);

  const filteredReasons = () => {
    if (currentWord === "") return reasons;
    else {
      return reasons.filter((reason) => {
        const reasonFiltered = reason.description
          .toLowerCase()
          .split(" ")
          .map((str) => str.replace(/[^a-z\u00C0-\u017F]/gi, ""));
        if (reasonFiltered.includes(currentWord)) {
          return reason;
        }
      });
    }
  };

  return (
    <>
      <div className="frequent-container">
        {isLoading && (
          <div className="loading-centered-container">
            <Spinner
              animation="border"
              variant="success"
              className="loading-centered"
            />
          </div>
        )}
        {!isLoading && wordCloud.length !== 0 && (
          <div className="report-container">
            <h1 className="title-page-txt">
              Razones por la cual usuarios eliminaron su cuenta
            </h1>
            <div className="left-container">
              <h1 className="title-txt">Palabras frecuentes</h1>
              <div className="word-cloud">
                <ReactWordcloud
                  words={wordCloud}
                  maxWords={20}
                  options={options}
                  callbacks={callbacks}
                />
              </div>
              <div className="btn-refresh-container">
                <Button
                  id="btn-refresh-list"
                  type="button"
                  variant="contained"
                  startIcon={<RefreshIcon />}
                  onClick={() => refreshList()}
                >
                  Limpiar Selección
                </Button>
              </div>
            </div>
            <div className="report-list-container">
              <ReasonsReport
                currentWord={currentWord}
                reasons={filteredReasons()}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Report;
