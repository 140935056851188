import React from "react";
import SideNavItem from "./SideNavItem";
import "./style.css";
import { logout } from "../../Auth/sessionManagement";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import LogoRitsii from "../../Components/LogoRitsii";

export default function SideNav() {
  const isAdmin = localStorage.getItem("isAdmin");
  const items = [
    {
      name: "Tablero",
      to: "/dashboard",
    },
    {
      name: "Gestionar recetas",
      to: "/recipes",
      permissionRequired: false
    },
    {
      name: "Moderadores",
      to: "/moderators",
      permissionRequired: true
    },
    {
      name: "Deshabilitar cuentas",
      to: "/accounts",
      permissionRequired: false
    },
    {
      name: "Motivos de eliminación de cuenta",
      to: "/reports",
      permissionRequired: false
    },
  ];

  return (
    <aside className="Nav-Container">
      <div className="logo-ritsii-container">
        <LogoRitsii />
      </div>

      <div className="Nav-Container-List">
        <ul className="Nav-Container-List-Item">
          {items.map((item) => (
            <SideNavItem key={item.name} name={item.name} to={item.to} permissionRequired={item.permissionRequired}/>
          ))}
        </ul>
      </div>
      <div id="btn-container">
        <Button
          id="btn-logout"
          type="button"
          variant="contained"
          startIcon={<LogoutIcon />}
          onClick={() => logout()}
        >
          Cerrar sesión
        </Button>
      </div>
    </aside>
  );
}
