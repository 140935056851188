/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./RecipeInformationPage.css";
import { Paper, List, ListItem, ListItemText } from "@mui/material";
import FullScreenImageModal from "../../Components/Modal/CustomModals/FullScreenImageModal";

function CustomModalRecipes(props) {
  const { recipe } = props;
  const somePadding = {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  const [openModal, setOpenModal] = useState(false);
  const durationText =
    `${
      recipe.recipeDuration.hours !== "00"
        ? `${recipe.recipeDuration.hours}hs`
        : ""
    }` + ` ${recipe.recipeDuration.minutes}min`;

  return (
    <>
      <FullScreenImageModal
        image={recipe.imageUri}
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />

      <div className="grid-container-all-col">
        <div className="grid-container-one-col grid-first-column column-with-padding-left">
          <Paper
            elevation={8}
            sx={
              recipe.imageUri !== "empty"
                ? {
                    backgroundImage: `url(${recipe.imageUri})`,
                    backgroundSize: "cover",
                    backgroundColor: "transparent",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    cursor: "zoom-in",
                  }
                : somePadding
            }
            onClick={() => {
              if (recipe.imageUri !== "empty") {
                setOpenModal(true);
              }
            }}
          >
            {recipe.imageUri === "empty" && (
              <h1 className="card-title">La receta no posee imagen</h1>
            )}
          </Paper>
          <Paper elevation={8}>
            <h1 className="card-title specific-title-padding">Categorías:</h1>
            <List className="card-body">
              {recipe.categories.map((eachCategory, idx) => {
                return (
                  <ListItem
                    key={idx}
                    divider={idx !== recipe.categories.length - 1}
                    dense
                  >
                    <ListItemText
                      primary={eachCategory}
                      className="list-item-text"
                    ></ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </div>

        <div className="grid-container-one-col grid-second-third-column column-with-only-vertical-padding">
          <Paper elevation={8} sx={somePadding}>
            <h1 className="card-title">Duración:</h1>
            <h1 className="card-text">{durationText}</h1>
          </Paper>
          <Paper elevation={8}>
            <h1 className="card-title specific-title-padding">Ingredientes:</h1>
            <List className="card-body">
              {recipe.ingredients.map((eachIngredient, idx) => {
                return (
                  <ListItem
                    key={idx}
                    divider={idx !== recipe.ingredients.length - 1}
                    dense
                  >
                    <ListItemText
                      primary={`${eachIngredient.name} - ${eachIngredient.quantity} ${eachIngredient.measure}`}
                      className="list-item-text"
                    ></ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </div>
        <div className="grid-container-one-col grid-second-third-column column-with-padding-right">
          <Paper elevation={8} sx={somePadding}>
            <h1 className="card-title">Dificultad:</h1>
            <h1 className="card-text">{recipe.difficulty}</h1>
          </Paper>

          <Paper elevation={8}>
            <h1 className="card-title specific-title-padding">Pasos:</h1>
            <List className="card-body">
              {recipe.steps.map((eachStep, idx) => {
                return (
                  <ListItem
                    key={idx}
                    divider={idx !== recipe.steps.length - 1}
                    dense
                  >
                    <ListItemText
                      primary={`${eachStep.number} - ${eachStep.description}`}
                      className="list-item-text"
                    ></ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </div>
      </div>
    </>
  );
}

export default CustomModalRecipes;
