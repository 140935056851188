/* eslint-disable react/prop-types */
import React from "react";
import {
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import "./RecipeResolution.css";

function RecipeResolution(props) {
  const {
    recipeState,
    setRecipeState,
    observation,
    setObservation,
    disabledFields,
  } = props;
  const somePadding = {
    padding: "1rem",
    display: "flex",
  };

  return (
    <div className="grid-container-two-col">
      <Paper elevation={8} sx={somePadding}>
        <FormControl fullWidth disabled={disabledFields}>
          <InputLabel color="warning">Decisión a tomar</InputLabel>
          <Select
            value={recipeState}
            label="Decisión a tomar"
            color="warning"
            onChange={(val) => setRecipeState(val.target.value)}
          >
            <MenuItem value={0}>Aceptar</MenuItem>
            <MenuItem value={1}>Rechazar</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Paper elevation={8} sx={somePadding}>
        <TextField
          multiline
          rows={5}
          label="Observación"
          value={observation}
          fullWidth
          onChange={(val) => setObservation(val.target.value)}
          color="warning"
          disabled={disabledFields}
        />
      </Paper>
    </div>
  );
}

export default RecipeResolution;
