/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import "./RecipeSearchBar.css";

function RecipeSearchBar({ getDataFilter }) {
  const [selectedKindOfDate, setSelectedKindOfDate] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateUntil, setDateUntil] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleChangeKindOfDate = (val) => {
    setSelectedKindOfDate(val.target.value);
  };

  const handleChangeFrom = (newValue) => {
    setDateFrom(newValue);
  };

  const handleChangeUntil = (newValue) => {
    setDateUntil(newValue);
  };

  const handleBtnSearch = () => {
    if (
      selectedKindOfDate !== "" &&
      dateFrom !== "Invalid Date" &&
      dateUntil !== "Invalid Date"
    ) {
      getDataFilter({
        kindOfDate: selectedKindOfDate,
        dateFrom: dateFrom,
        dateUntil: dateUntil,
      });
    } else {
      setOpenSnack(true);
    }
  };

  const handleBtnCleanSelection = () => {
    getDataFilter(undefined);
    setSelectedKindOfDate("");
    setDateFrom(null);
    setDateUntil(null);
  };

  return (
    <div className="search-filter-container">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="search-title-container-inside-recipe">
          <h1 className="search-title">Filtrar resultados:</h1>
          <FormControl fullWidth>
            <InputLabel color="warning">Tipo de fecha</InputLabel>
            <Select
              value={selectedKindOfDate}
              label="Tipo de fecha"
              placeholder="Tipo de fecha"
              color="warning"
              onChange={handleChangeKindOfDate}
            >
              <MenuItem value={0}>Fecha de creación</MenuItem>
              <MenuItem value={1}>Fecha de aceptación</MenuItem>
              <MenuItem value={2}>Fecha de rechazo</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="date-picker-container-inside-recipe ">
          <div className="date-picker-container-inside-recipe-left">
            <DesktopDatePicker
              sx={{ padding: "0.5rem" }}
              label="Desde"
              inputFormat="DD/MM/YYYY"
              value={dateFrom}
              onChange={handleChangeFrom}
              renderInput={(params) => {
                params.inputProps = { ...params.inputProps, readOnly: true };
                return <TextField {...params} color="warning" />;
              }}
              maxDate={moment(new Date().toISOString()).format()}
              fullWidth
            />
          </div>
          <div className="date-picker-container-inside-recipe-right">
            <DesktopDatePicker
              label="Hasta"
              inputFormat="DD/MM/YYYY"
              value={dateUntil}
              onChange={handleChangeUntil}
              renderInput={(params) => {
                if (params.error) {
                  params.inputProps = {
                    ...params.inputProps,
                    readOnly: true,
                    value: moment(new Date().toISOString()).format(
                      "DD/MM/YYYY"
                    ),
                  };
                  params.error = false;
                } else {
                  params.inputProps = { ...params.inputProps, readOnly: true };
                }
                return <TextField {...params} color="warning" />;
              }}
              minDate={dateFrom}
              maxDate={moment(new Date().toISOString()).format()}
              fullWidth
            />
          </div>
          <div className="search-button-container search-button-container-right">
            <Button
              sx={{ height: "3rem" }}
              id="btn-filter-list"
              type="button"
              variant="contained"
              endIcon={<ManageSearchIcon />}
              onClick={() => handleBtnSearch()}
            >
              Buscar en periodo
            </Button>
          </div>
          <div className="search-button-container search-button-container-left">
            <Button
              sx={{ height: "3rem" }}
              id="btn-refresh-list"
              type="button"
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={() => handleBtnCleanSelection()}
            >
              Limpiar Selección
            </Button>
          </div>
        </div>
      </LocalizationProvider>
      <Snackbar
        open={openSnack}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity="warning">
          Todos los campos de la búsqueda son obligatorios.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RecipeSearchBar;
