import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import moment from 'moment';
import { Spinner } from "react-bootstrap";
import { getChartsReports } from "../../services/reports/reports";
import { options, optionsLine, optionsBar, optionsHorizontalBar, optionsPie } from "./utils/ChartOptions"
import './Dashboard.css'


export default function Dashboard() {
  const [accountsSeries, setAccountsSeries] = useState();
  const [functionalityChartSeries, setFunctionalityChartSeries] = useState();
  const [listsStatesChartSeries, setListsStatesChartSeries] = useState();
  const [communityIssuesStatesChartSeries, setCommunityIssuesStatesChartSeries] = useState();
  const [isReady, setIsReady] = useState(false);

  const refContainer = useRef();
  const [dimensions, setDimensions] =
    useState({ width: 0, height: 0 });

  useEffect(() => {
    async function fetchData() {
      function handleWindowResize() {
        if (refContainer.current) {
          setDimensions({
            width: refContainer.current.offsetWidth,
            height: refContainer.current.offsetHeight,
          });
        }
      }
      window.addEventListener('resize', handleWindowResize);
      getChartsReports().then((res) => {
        let accountsCreatedArray = [];
        let accountsDeletedArray = [];
        let totalArray = [0, 0]
        res.response['accountsChart'].forEach(item => {
          let accountDate = moment(item.date, "DD/MM/YYYY");
          totalArray[0] += item.accountsCreatedQuantity
          totalArray[1] += item.accountsDeletedQuantity
          accountsCreatedArray.push([accountDate.valueOf(), item.accountsCreatedQuantity])
          accountsDeletedArray.push([accountDate.valueOf(), item.accountsDeletedQuantity])
        })

        let xaxis
        if (accountsCreatedArray.length > 0) {
          xaxis = {
            min: accountsCreatedArray[0][0],
          }
          if (accountsCreatedArray.length > 10) {
            xaxis['max'] = accountsCreatedArray[9][0]
          } else {
            xaxis['max'] = accountsCreatedArray[accountsCreatedArray.length - 1][0]
          }
          optionsLine.chart.selection.xaxis = xaxis
        } else {
          optionsLine.chart.selection.enabled = false
        }
        let legendFormatter = function (seriesName, opts) {
          return seriesName + " (" + totalArray[opts.seriesIndex] + ")"
        };
        options.legend.formatter = legendFormatter;
        optionsLine.legend.formatter = legendFormatter;
        if (refContainer.current) {
          setDimensions({
            width: refContainer.current.offsetWidth,
            height: refContainer.current.offsetHeight,
          });
          setAccountsSeries([
            {
              name: "Cuentas creadas",
              data: accountsCreatedArray
            },
            {
              name: "Cuentas eliminadas",
              data: accountsDeletedArray
            },
          ])
          setFunctionalityChartSeries([{
            data: [{
              x: "Rec. Comunidad",
              y: res.response['functionalityChart']['communityRecipesCreatedQuantity']
            },
            {
              x: "Votaciones ráp.",
              y: res.response['functionalityChart']['fastVotationsCreatedQuantity']
            },
            {
              x: "Listas",
              y: res.response['functionalityChart']['listsCreatedQuantity']
            },
            {
              x: "Menús",
              y: res.response['functionalityChart']['menusCreatedQuantity']
            },
            {
              x: "Recetas",
              y: res.response['functionalityChart']['myRecipesCreatedQuantity']
            },],
            name: 'Cantidad'
          }])
          setListsStatesChartSeries([{
            data: [{
              x: "Editando",
              y: res.response['listsStatesChart']['editingListsQuantity']
            },
            {
              x: "Activadas",
              y: res.response['listsStatesChart']['activatedListsQuantity']
            },
            {
              x: "Finalizadas",
              y: res.response['listsStatesChart']['finishedListsQuantity']
            }],
            name: 'Cantidad'
          }])

          setCommunityIssuesStatesChartSeries([
            res.response['communityIssuesStatesChart']['pendingIssuesQuantity'],
            res.response['communityIssuesStatesChart']['aceptedIssuesQuantity'],
            res.response['communityIssuesStatesChart']['rejectedIssuesQuantity']
          ])
          setIsReady(true);
        }
      });
      return () => { window.removeEventListener('resize', handleWindowResize); };
    }
    fetchData();
  }, []);


  return (
    <div className="frequent-container" ref={refContainer}>
      {!isReady ?
        <div className="loading-centered-container">
          <Spinner
            animation="border"
            variant="success"
            className="loading-centered"
          />
        </div>
        :
        <div >
          <div className="dashboard-column">
            <div className="dashboard-square" id="wrapper">
              <div className="dashboard-card">
                <div id="chart-line2">
                  <Chart options={options} series={accountsSeries} type="line" height={((dimensions.height / 2) - 90) * 0.64} width={(dimensions.width / 2) - 70} />
                </div>
                <div id="chart-line">
                  <Chart options={optionsLine} series={accountsSeries} type="area" height={((dimensions.height / 2) - 90) * 0.36} width={(dimensions.width / 2) - 60} />
                </div>
              </div>
            </div>
            <div className="dashboard-square" >
              <div className="dashboard-card">
                <Chart options={optionsBar} series={functionalityChartSeries} type="bar" height={((dimensions.height / 2) - 75)} width={(dimensions.width / 2) - 60} />
              </div>
            </div>
          </div>
          <div className="dashboard-column">
            <div className="dashboard-square" >
              <div className="dashboard-card">
                <Chart options={optionsHorizontalBar} series={listsStatesChartSeries} type="bar" height={((dimensions.height / 2) - 75)} width={(dimensions.width / 2) - 70} />
              </div>
            </div>
            <div className="dashboard-square" >
              <div className="dashboard-card">
                <Chart options={optionsPie} series={communityIssuesStatesChartSeries} type="pie" height={((dimensions.height / 2) - 28)} width={(dimensions.width / 2) - 60} />
              </div>
            </div>
          </div>
        </div >
      }
    </div>
  )
}