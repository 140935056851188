import { axiosInstance as axios } from "../../api/axios";

function getHeaders() {
  let token = localStorage.getItem("jwt");
  const headers = {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return headers;
}

async function getUsers() {
  let status;
  let response;
  const header = getHeaders();
  await axios
    .get("users", {
      headers: header,
    })
    .then((res) => {
      status = res.status;
      response = res.data;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status) {
          status = error.response.status;
        }
      }
    });
  return { status: status, response: response };
}

async function modifyUser(user, newState) {
  let status;
  let response;
  const header = getHeaders();
  await axios
    .patch(
      "users",
      {
        user: user,
        newState: newState,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      status = res.status;
      response = res.data;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status) {
          status = error.response.status;
        }
      }
    });
  return { status: status, response: response };
}

export { getUsers, modifyUser };
