import React, { useContext, useState, useEffect } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import logo from "../../Assets/Images/Ritsii-logo-login.svg";
import leftVector from "../../Assets/Images/leftVector.svg";
import rightVector from "../../Assets/Images/rightVector.svg";
import { login } from "../../Auth/sessionManagement";
import { AuthContext } from "../../Contexts/AuthContext";
import AlertDismissible from "../../Components/Alerts/AlertDismissible";

export default function Login() {
  const [errorMsg, setErrorMsg] = useState("");

  const { setLoggedIn } = useContext(AuthContext);

  const [alert, setAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: "onSubmit" });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = ({ username, password }) => {
    setLoading(true);
    login(username, password).then((status) => {
      if (status === 200) {
        setLoading(false);
        setLoggedIn(true);
      }
      if (status === 403) {
        setErrorMsg("Datos ingresados incorrectos");
        setAlert(true);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (
      errors.username &&
      errors.username.type === "required" &&
      !errors.password
    ) {
      setErrorMsg("Campo requerido: Usuario");
      setAlert(true);
    }
    if (
      errors.password &&
      errors.password.type === "required" &&
      !errors.username
    ) {
      setErrorMsg("Campo requerido: Contraseña");
      setAlert(true);
    } else {
      if (
        errors.username &&
        errors.username.type === "required" &&
        errors.password &&
        errors.password.type === "required"
      ) {
        setErrorMsg("Campos requeridos: Usuario, Contraseña");
        setAlert(true);
      }
    }
  }, [errors.username, errors.password]);

  return (
    <div className="container-login">
      <div className="container-form">
        <img src={rightVector} alt="" id="right-vector-login" />
        <img src={leftVector} alt="" id="left-vector-login" />
        <img src={logo} alt="LogoRitsii" id="logo-login" />
        <form onSubmit={handleSubmit(onSubmit)} id="form-container">
          <div className="txt-and-label-container">
            <label id="txt">Usuario</label>
            <input
              type="text"
              defaultValue=""
              className="input"
              disabled={isLoading}
              maxLength="30"
              {...register("username", {
                required: true,
              })}
            />
          </div>
          <div className="txt-and-label-container">
            <label id="txt">Contraseña</label>
            <input
              type="password"
              defaultValue=""
              className="input"
              disabled={isLoading}
              maxLength="30"
              {...register("password", {
                required: true,
              })}
            />
          </div>
          <div className="btn-container">
            <Button id="btn-login" type="submit" disabled={isLoading}>
              {isLoading ? "INGRESANDO..." : "INICIAR SESIÓN"}
            </Button>
          </div>
        </form>
        <div className="alert-space">
          <AlertDismissible
            value={alert}
            onChange={(val) => setAlert(val)}
            alertType="danger"
            buttonType="outline-danger"
            text={errorMsg}
          />
        </div>
      </div>
    </div>
  );
}
