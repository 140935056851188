import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import ModalCustomized from "../Modal";
import { delete_moderator, get_moderators_admins } from "../../services/administrators";
import './style.css';

export default function TableCustomized () {
  const defaultObject = [{
    Nombre: '',
    Apellido: '',
    Email: ''
  }]
  const [show, setShow] = useState(false)
  const columns = ['Nombre', 'Apellido','Email', 'Acciones']
  const [rowsProp, setRowsContent] = useState(defaultObject)
  const [selectedName, setSelectedName] = useState('')
  const [selectedLastName, setSelectedLastName] = useState('')
  const [selectedEmail, setSelectedEmail] = useState('')

  const handleOnClick = (email, name, lastName) => {
    setSelectedName(name)
    setSelectedLastName(lastName)
    setSelectedEmail(email)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleSave = () => {
    delete_moderator(selectedEmail).then(response => {
      setShow(false)
      get_moderators_admins().then(response => {
        if(response.data){
          setRowsContent(response.data)
        }else{
          setRowsContent(defaultObject)
        }
      })
    })
  }

  useEffect(() => {
    get_moderators_admins().then(response =>  {
      if(response.data){
        setRowsContent(response.data)
      }
    })
  }, []);

  return(
    <Table striped bordered hover>  
        <thead>
          <tr>
            {
              columns.map(column => <th key={column}>{column}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            rowsProp.map(row => {
              return(
                <tr key={row.Email}>
                  <td>{row.Nombre}</td>
                  <td>{row.Apellido}</td>
                  <td>{row.Email}</td>
                  <td>
                    {row.Email ? 
                      <DeleteIcon className="Table-Delete-Button" onClick={() => handleOnClick(row.Email, row.Nombre, row.Apellido)}/>
                    : 
                      <></>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
        <ModalCustomized
          show={show}
          title={'¿Está seguro que desea eliminar un moderador?'}
          message={'Se eliminará el moderador ' + selectedLastName + ' ' + selectedName}
          okTest={'Eliminar'}
          handleClose={handleClose} 
          handleSave={handleSave}
        ></ModalCustomized>
    </Table>
  )
}
