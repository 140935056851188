import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";

export default function ModalCustomized(params) {
  let {
    show,
    message,
    handleClose,
    handleSave,
    ok,
    deny,
    okText,
    denyText,
    title,
    centered,
    dialogClass,
  } = params;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={centered}
      dialogClassName={dialogClass}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      {message && (
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
      )}

      <Modal.Footer>
        {deny ? (
          <Button id="Modal-Button-Close" onClick={handleClose}>
            {denyText ? denyText : "Close"}
          </Button>
        ) : (
          <></>
        )}
        {ok ? (
          <Button id="Modal-Button-Save" onClick={handleSave}>
            {okText ? okText : "Save changes"}
          </Button>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ModalCustomized.defaultProps = {
  message: "",
  ok: true,
  deny: true,
  okText: "Guardar",
  denyText: "Cerrar",
  title: "Ingrese el titulo",
};
